<template>
  <div>
    <a-form
      id="components-form-demo-normal-login"
      :form="form"
      @submit="handleSubmit"
    >
      <a-form-item>
        <a-input
          v-decorator="['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入名称' },
              { max: 50, message: '最多50个字符' },
            ]
          }]"
          placeholder="请输入公司名称,字数不能超过50个"
        />
      </a-form-item>

      <a-form-item>
        <a-spin :spinning="loadingRegionOption">
          <a-cascader
            :field-names="{ label: 'name', value: 'id', children: 'cities' }"
            :options="regionOptions"
            :show-search="{ regionFilter }"
            placeholder="请选择所在城市"
            v-decorator="['region']"
          />
        </a-spin>
      </a-form-item>

      <a-form-item>
        <a-input
          v-decorator="['user.username', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入账号' },
              { pattern: /^[a-zA-Z0-9_]{4,50}$/, message: '账号需为4-50位英文或数字组合' }
            ]
          }]"
          placeholder="请设置登录账号,4-50位英文或数字"
        />
      </a-form-item>

      <a-form-item>
        <a-input
          autocomplete="new-password"
          v-decorator="['user.password', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入密码' },
              { min: 6, message: '密码需要在6位数以上' },
              { pattern: /^\S.*\S$/, message: '首尾不能为空格' },
              { validator: validateToNextPassword }
            ]
          }]"
          placeholder="请设置6位以上登录密码"
          type="password"
        />
      </a-form-item>

      <a-form-item>
        <a-input
          autocomplete="new-password"
          v-decorator="['user.confirm_password', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请再次输入密码' },
              { validator: compareToFirstPassword }
            ]
          }]"
          placeholder="请再次输入登录密码"
          type="password"
          @blur="handleConfirmBlur"
        />
      </a-form-item>

      <a-form-item>
        <a-input
          v-decorator="['user.full_name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入姓名' },
            ]
          }]"
          placeholder="请输入姓名"
        />
      </a-form-item>

      <a-form-item>
        <a-select
          placeholder="请选择责任销售(选填)"
          show-search
          allow-clear
          option-filter-prop="children"
          :filter-option="filterOption"
          :loading="loadingSaleOption"
          v-decorator="['sale_id']"
        >
          <a-select-option
            v-for="user in salesOptions"
            :key="user.id"
            :value="user.id"
          >
            {{ user.full_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button
          :loading="submitting"
          type="primary"
          html-type="submit"
          class="register-form-button"
        >
          创建账号
        </a-button>
      </a-form-item>

    </a-form>
  </div>
</template>

<script>
import { findRegionWithoutDistrict } from '@/api/region'
import { findCanTrackRegisteredAccountSaleOptions } from '@/api/user'
export default {
  name: 'RegisterNextStep',
  data() {
    return {
      submitting: false,
      form: this.$form.createForm(this, { name: 'register_next_step' }),
      regionOptions: [],
      salesOptions: [],
      loadingRegionOption: true,
      loadingSaleOption: true,
      confirmDirty: false
    }
  },
  created() {
    this.fetchRegionOptions()
    this.fetchSalesOptions()
  },
  methods: {
    // 加载省市选项
    fetchRegionOptions() {
      this.loadingRegionOption = true
      findRegionWithoutDistrict().then((res) => {
        if (res.code === 0) {
          this.regionOptions = res.data
        }
        this.loadingRegionOption = false
      })
    },

    fetchSalesOptions() {
      this.loadingSaleOption = true
      findCanTrackRegisteredAccountSaleOptions().then((res) => {
        if (res.code === 0) {
          this.salesOptions = res.data
          this.loadingSaleOption = false
        }
      })
    },

    regionFilter(inputValue, path) {
      return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    handleConfirmBlur(e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },

    compareToFirstPassword(rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('user.password')) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入相同密码')
      } else {
        callback()
      }
    },

    validateToNextPassword(rule, value, callback) {
      const form = this.form
      if (value && this.confirmDirty) {
        form.validateFields(['user.confirm_password'], { force: true })
      }
      callback()
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          this.$emit('completed', values)
          this.submitting = false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.register-form-button {
  width: 100%;
}
</style>
