import request from '@/utils/request'

// 查询所有省市区
export function findRegions() {
  return request({
    url: `/regions`,
    method: 'get'
  })
}

// 查询所有省市
export function findRegionWithoutDistrict() {
  return request({
    url: `/regions_without_district`,
    method: 'get'
  })
}
