var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
          normalize: this.$lodash.trim,
          rules: [
            { required: true, message: '请输入名称' },
            { max: 50, message: '最多50个字符' } ]
        }]),expression:"['name', {\n          normalize: this.$lodash.trim,\n          rules: [\n            { required: true, message: '请输入名称' },\n            { max: 50, message: '最多50个字符' },\n          ]\n        }]"}],attrs:{"placeholder":"请输入公司名称,字数不能超过50个"}})],1),_c('a-form-item',[_c('a-spin',{attrs:{"spinning":_vm.loadingRegionOption}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:(['region']),expression:"['region']"}],attrs:{"field-names":{ label: 'name', value: 'id', children: 'cities' },"options":_vm.regionOptions,"show-search":{ regionFilter: _vm.regionFilter },"placeholder":"请选择所在城市"}})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user.username', {
          normalize: this.$lodash.trim,
          rules: [
            { required: true, message: '请输入账号' },
            { pattern: /^[a-zA-Z0-9_]{4,50}$/, message: '账号需为4-50位英文或数字组合' }
          ]
        }]),expression:"['user.username', {\n          normalize: this.$lodash.trim,\n          rules: [\n            { required: true, message: '请输入账号' },\n            { pattern: /^[a-zA-Z0-9_]{4,50}$/, message: '账号需为4-50位英文或数字组合' }\n          ]\n        }]"}],attrs:{"placeholder":"请设置登录账号,4-50位英文或数字"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user.password', {
          normalize: this.$lodash.trim,
          rules: [
            { required: true, message: '请输入密码' },
            { min: 6, message: '密码需要在6位数以上' },
            { pattern: /^\S.*\S$/, message: '首尾不能为空格' },
            { validator: _vm.validateToNextPassword }
          ]
        }]),expression:"['user.password', {\n          normalize: this.$lodash.trim,\n          rules: [\n            { required: true, message: '请输入密码' },\n            { min: 6, message: '密码需要在6位数以上' },\n            { pattern: /^\\S.*\\S$/, message: '首尾不能为空格' },\n            { validator: validateToNextPassword }\n          ]\n        }]"}],attrs:{"autocomplete":"new-password","placeholder":"请设置6位以上登录密码","type":"password"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user.confirm_password', {
          normalize: this.$lodash.trim,
          rules: [
            { required: true, message: '请再次输入密码' },
            { validator: _vm.compareToFirstPassword }
          ]
        }]),expression:"['user.confirm_password', {\n          normalize: this.$lodash.trim,\n          rules: [\n            { required: true, message: '请再次输入密码' },\n            { validator: compareToFirstPassword }\n          ]\n        }]"}],attrs:{"autocomplete":"new-password","placeholder":"请再次输入登录密码","type":"password"},on:{"blur":_vm.handleConfirmBlur}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user.full_name', {
          normalize: this.$lodash.trim,
          rules: [
            { required: true, message: '请输入姓名' } ]
        }]),expression:"['user.full_name', {\n          normalize: this.$lodash.trim,\n          rules: [\n            { required: true, message: '请输入姓名' },\n          ]\n        }]"}],attrs:{"placeholder":"请输入姓名"}})],1),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sale_id']),expression:"['sale_id']"}],attrs:{"placeholder":"请选择责任销售(选填)","show-search":"","allow-clear":"","option-filter-prop":"children","filter-option":_vm.filterOption,"loading":_vm.loadingSaleOption}},_vm._l((_vm.salesOptions),function(user){return _c('a-select-option',{key:user.id,attrs:{"value":user.id}},[_vm._v(" "+_vm._s(user.full_name)+" ")])}),1)],1),_c('a-form-item',[_c('a-button',{staticClass:"register-form-button",attrs:{"loading":_vm.submitting,"type":"primary","html-type":"submit"}},[_vm._v(" 创建账号 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }